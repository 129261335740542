<template>
  <div class="content">
      <div class='main'>
        <el-row>
        <el-col :span="3">
          <ul>
            <li v-for="item in navList" :key="item.id"  
            :class="{ active: item.id == activeId }" @click="toggleRoute(item.id, item.route)"><span class="icon"><img :src="OSS+item.url" alt="" style='transform: translateY(4px);'></span><span slot="title">{{item.name}}</span></li>
          </ul>
        </el-col>
        <el-col :span="21">
          <router-view ></router-view>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import printRecordSearch from './printRecordSearch'
export default {
  components:{
    printRecordSearch
  },
    data(){
      return {
        activeId:1,
        navList: [
        {
          id: 1,
          name: "打印记录",
          route: "/utilities/printRecordSearch",
          url:"/uncommon1.png"
        },
        {
          id: 2,
          name: "发货记录",
          route: "/utilities/deliverGoodsRecord",
          url:"/uncommon2.png"
        },
        {
          id: 3,
          name: "售后订单",
          route: "/utilities/afterSalesOrder",
          url:"/uncommon3.png"
        },
       
      ],
      }
    },
    methods:{
        toggleRoute(id, route){
          this.activeId = id;
          this.$router.push({path:route})
        }
    },
    mounted() {
      this.activeId = window.sessionStorage.getItem("activeSId");
  },
  created() {
    //  this.$router.push({path:'/utilities/printRecordSearch'})
  },
   
}
</script>

<style  scoped>
  .content {
    padding: 10px 10px 0px;
    background: linear-gradient(#E6E0D0, #ffffff);
  }
  .main {
    background-color: white;
    border-radius:10px;
  }
  .main>.el-row {
    margin-right: 0px;
  }
  ul {
    width: 145px;
    background-color: #F4F4F4;
    padding-top: 10px;
    border-radius: 10px 0px 0px;
  } 
  ul>li {
    padding: 15px 10px;
    text-align: center;
    font-size: 15px;
    border: 1px solid transparent;
    border-right: 1px solid #E2E2E2;
  }  
  ul>li:hover {
    cursor: pointer;
    background-color: white;
    border: 1px solid transparent;
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
  }
  .active {
    cursor: pointer;
    background-color: white;
    border: 1px solid transparent;
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
  }
  .icon {
    margin-right: 5px;
    width: 25px;
    height: 25px;
  
  }

</style>